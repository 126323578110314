import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"
import { Link } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"

import * as blogStyles from "../pages/blog.module.scss"

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
      html
      id
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const Blog = props => {
  let disqusConfig = {
    url: `${props.data.site.siteMetadata.siteUrl + props.location.pathname}`,
    identifier: props.data.markdownRemark.id,
    title: props.data.markdownRemark.frontmatter.title,
  }
  return (
    <Layout>
      <Head title={props.data.markdownRemark.frontmatter.title} />
      <h1>{props.data.markdownRemark.frontmatter.title}</h1>
      <p>
        by <b>Nichole Treadway</b>
      </p>
      <p>{props.data.markdownRemark.frontmatter.date}</p>
      {props.data.markdownRemark.frontmatter.tags && (
        <div className={blogStyles.tags}>
          {props.data.markdownRemark.frontmatter.tags.map(tag => (
            <Link key={tag} to={`/tags/${tag}`} className={`tag-${tag}`}>
              {tag}
            </Link>
          ))}
        </div>
      )}
      <div
        dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
      ></div>

      <Disqus config={disqusConfig} />
    </Layout>
  )
}

export default Blog
